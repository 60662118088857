
<template>
    <div class="accep bgblue">
        <div class="user">
            <div class="form" style="margin-left: 20px;padding: 10px;">
               <el-form
               
                :model="formInline"
                class="demo-form-inline"
                style="position: relative;"
                label-width="85px"
              >
                <el-row>
          <el-col :span="4"
            ><el-form-item label="验收单号">
              <el-input
                v-model="formInline.user"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="4"
            ><el-form-item label="客户名称">
              <el-input
                v-model="formInline.user"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="4"
            ><el-form-item label="客户姓名">
              <el-input
                v-model="formInline.user"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>

          <el-col :span="4"
            ><el-form-item label="手机">
              <el-input
                v-model="formInline.user"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="4"
            ><el-form-item label="证件号">
              <el-input
                v-model="formInline.user"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="4"
            ><el-form-item label="手机号">
              <el-input
                v-model="formInline.user"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>

          <el-col :span="4"
            ><el-form-item label="电站类型">
              <el-input
                v-model="formInline.user"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="4"
            ><el-form-item label="验收类型">
              <el-select v-model="formInline.region" placeholder="请选择">
                <el-option
                  v-for="item in roofNum"
                  :key="item.region"
                  :label="item.label"
                  :value="item.region"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="4"
            ><el-form-item label="超时状态">
              <el-select v-model="formInline.region" placeholder="请选择">
                <el-option
                  v-for="item in roofNum"
                  :key="item.region"
                  :label="item.label"
                  :value="item.region"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>

          <el-col :span="4"
            ><el-form-item label="验收复查">
              <el-select v-model="formInline.region" placeholder="请选择">
                <el-option
                  v-for="item in roofNum"
                  :key="item.region"
                  :label="item.label"
                  :value="item.region"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="4"
            ><el-form-item label="安装单据类型">
              <el-select v-model="formInline.region" placeholder="请选择">
                <el-option
                  v-for="item in roofNum"
                  :key="item.region"
                  :label="item.label"
                  :value="item.region"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="4" style="text-align: right;">
                      <el-button type="primary">
                        <i class="el-icon-search"></i>查询</el-button
                      >
                      <el-button plain>
                        <i class="el-icon-refresh"></i>重置</el-button
                      >
                    </el-col>
                </el-row>
                
               </el-form>
            </div>
        </div>
        <div class="biao">
            <el-row>
        <el-button type="primary" plain>
          <i class="el-icon-upload2"></i>&nbsp;&nbsp;导出</el-button
        >
       
            </el-row>
            <el-table
        :data="tableData"
        style="width: 100%"
        height="calc(100vh - 320px)"
        border
        :header-cell-style="{
          'background-color': '#f2f2f2',
         
          'text-align': 'center',
          border: '1px solid #ddd',
        }"
      >
        <el-table-column type="selection" width="50"> </el-table-column>
        <el-table-column
          prop=" "
          label="操作"
          width="60"
          align="center"
          
        >
        <template slot-scope="scope">
          <el-button type="warning" style="padding: 0 5px;"  @click="sh(scope.row.explorateCode)">审核</el-button>
        </template>
        
        </el-table-column>
        <el-table-column
          prop="company"
          label="验收单号"
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="客户名称"
          width="80"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="exploeNum"
          label="手机号"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="acheiveNum"
          label="地址"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="agentName"
          label="电站类型"
          width="230"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="agentName"
          label="验收类型"
          width="230"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="agentName"
          label="超时状态"
          width="200"
          align="center"
        >
        </el-table-column>
        
        <el-table-column
          prop="idNumber"
          label="验收复查"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="installionState"
          label="安装单据类型"
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="cooperationType"
          label="验收日期"
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="reviewResults"
          label="验收视频"
          width="100"
          align="center"
        >
        </el-table-column>
      
      </el-table>
        </div>
        <el-dialog
          title="购售电合同审核"
          :visible.sync="dialogVisible"
          width="40%"
          :before-close="handleClose">
          <div class="box-dia">
            <div class="bo-tit">购售电合同:</div>
            <div class="wj">
                <img src="../../assets/images/PDF.png" alt="">
                <div>购售电合同文件</div>

            </div>
          </div>
          <div style="text-align: right;">
            <el-button style="width: 105px;height: 43px;" @click="dialogVisible = false">取 消</el-button>
            <el-button style="width: 105px;height: 43px;" type="primary" @click="dialogVisible = false">选择</el-button>
          </div>
          
        </el-dialog>
    </div>
</template>
  
  <script>
  export default {
    methods: {
      sh(id){
        this.dialogVisible = true
      }
     
    },
  
    data() {
      return {
        formInline:{
            user:""
        },
        dialogVisible:false,
        tableData: [
        {
          company: "皖北分公司",
          name: "陈国芳",
          exploeNum: "202302281545082308",
          acheiveNum: "202304131400474780",
          agentName: "亳州市来裕新能源科技有限公司",
          area: "李朋",
          phone: "18709885078",
          idNumber: "341281196610104663",
          installionState: "安装审批结束",
          cooperationType: "惠农宝",
          reviewResults: "未复查",
          review: "---",
          installationRejection: "1",
          explorationRejection: "0",
          explorationData: "---",
          installationDatatype: "普通单据",
          colorSteel: "否",
          explorationState: "勘探审批结束",
          powerStation: "用户电站",
          explorationAnomalies: "正常",
          installationException: "正常",
          explorationSinglechip: "660W",
          componentSpecifications: "72版型",
          installationType: "人字坡(低风压)七排",
          roofType: "阳光棚屋顶",
          gridconnectionType: "单户入网",
          numberPlanblocks: "46",
          explorationPower: "36960",
          explorationAddress: "安徽省亳州市谯城区309省道32-4号靠近福华驾校",
          baseAddress: "安徽省亳州市谯城区309省道32-4号靠近福华驾校",
          explorationRemarks:
            "房屋高11米,现浇顶,顶板厚度25公分,阳光棚7排,南4北3,南北出墙70公分,东西不出墙，施工前，彩钢瓦移除，无其他遮挡",
          explorationCreator: "秦祥宇",
          explorationCreationtime: "2023-02-28 15:45:04",
          explorationSubmitter: "秦祥宇",
          explorationReviewtime: "2023-02-28 16:07:12",
          installingSinglechippower: "陶鼎文",
          numberofInstallationblocks: "2023-02-28 16:07:25",
          installionPower: "660W",
          inverterColumnnumber: " ",
          numberofInverters: "46",
          installationAddress: "TM30KBT221114128",
          installationNotes: "安徽省亳州市谯城区中心街靠近城父福华驾校",
          installationCreator: "1",
          installationCreationtime: "安徽省亳州市谯城区中心街靠近城父福华驾校",
          installationSubmitter: "",
          installationSubmissiontime: "",
          installedBy: "",
          installationUpdatetime: "",
          installationPreliminaryreviewer: "",
          installationInitialreviewtime: "",
          installationReviewperson: "",
          installationReviewtime: "",
          financialTrackingnumber: "",
          powerStationPSID: "",
          explorationReviewsubmissiontime: "",
          installationReviewsubmissiontime: "",
        },
      ],
      }
    },
  };
  </script>
  
  <style lang="less" scoped>
  .accep {
    padding: 10px;
    .user {
        background-color: #fff;
    }
    .biao {
        overflow: auto;
        background-color: #fff;
        margin-top: 20px;
        padding-top: 10px;
    }
    .box-dia {
        .bo-tit {
            color: #333333;
            font-size: 18px;
            border-top: 1px solid #d7d7d7;
           padding:10px 0 10px 30px;
           
        }
        .wj {
            text-align: center;
            width:150px ;
            margin-left: 30px;
            img {
                width: 150px;
                height: 150px;
                padding: 2px 2px 2px 2px;
                border-radius: 5px;
                border: 1px solid #d7d7d7;
            }
            div {
                color: #aaaaaa;
                font-size: 15px;
                margin-top: 10px;
            }
        }
    }
  }
  /deep/.el-table {
    height: calc(100vh - 500px);
  }
::v-deep
  .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td {
  background-color:  #f59a23 !important;
}
/deep/ .el-table td {
    padding: 4px 0;

  }
  /deep/ .el-form-item__label {
    
    font-size: 11px;
  }
  
  
  
  </style>