import { render, staticRenderFns } from "./accep.vue?vue&type=template&id=047d3102&scoped=true"
import script from "./accep.vue?vue&type=script&lang=js"
export * from "./accep.vue?vue&type=script&lang=js"
import style0 from "./accep.vue?vue&type=style&index=0&id=047d3102&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "047d3102",
  null
  
)

component.options.__file = "accep.vue"
export default component.exports